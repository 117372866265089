import { flatMap, memoize } from "lodash-es"
import { RouteLocation } from "vue-router"

import { KycAnswer, Question } from "../types"

/**
 * Flat all questions from all groups
 */
export const memoFlatAlQuestions = memoize((groups) => {
  return flatMap(groups, "questions")
})

/**
 * Check if question has been answered and the value is valid
 * Ignores paragraph uiType
 * @param {*} question - Question to check
 * @param {*} answers - Object of answers to check
 * @returns true if question has been answered and the value is valid OR its uiType is ignored, false otherwise
 */
export function checkQuestionValidness(
  question: Question,
  answers: KycAnswer = {},
  ignoredUiTypes = ["Paragraph"]
): boolean {
  if (!Object.keys(answers)?.length || !question?.id) return false
  if (!question.mandatory) return true

  const isIgnoredUiType = ignoredUiTypes.includes(question.uiType)

  if (isIgnoredUiType) return true

  const existsInAnswers = question.id in answers
  const hasValue = Boolean(answers?.[question.id])

  return hasValue && existsInAnswers
}

/**
 * Converts questions details to user type object in order to submit `POST /marketing` request
 * @param {*} questions - Array of questions to check
 * @param {*} answers - Object of answers to check
 * @returns Object of user type
 */
export function createMarketingDataFromAnswers(questions: Question[], answers: KycAnswer): KycAnswer {
  const data = questions.reduce((acc, { id, saveToUser }) => {
    if (isNullOrUndefined(saveToUser) || !saveToUser) return acc

    return { ...acc, [saveToUser]: answers[id] }
  }, {} as KycAnswer)

  return jsonClean(data) // Removes all undefined values
}

/** Autocomplete map for html input fields */
export const kycAutocompleteFields = {
  firstName: "given-name",
  lastName: "family-name",
  email: "email",
} as const

/**
 * Converts answers object to array of objects to submit `POST /kyc` request
 * @param {*} answers - Object of answers to check
 * @returns Array of objects to submit `POST /kyc`
 * @example
 * answersObjectToArray({
 *  Q1: "John",
 *  Q2: "Doe",
 * })
 * @returns @example
 * [
 *   {
 *     questionId: "Q1",
 *     answer: "John",
 *   },
 *   {
 *     questionId: "Q2",
 *     answer: "Doe",
 *   },
 * ]
 */
export function answersObjectToArray(
  answers: KycAnswer
): { questionId: string; answer: string | number | boolean | null }[] {
  return Object.entries(answers)
    .map(([key, value]) => {
      return {
        questionId: key,
        answer: value,
      }
    })
    .filter(({ answer }) => !isNullOrUndefined(answer)) // Filtering null or undefined values
}

export const mockInvAnswers = () => ({
  Q1: genUID().slice(0, 3),
  Q2: genUID().slice(0, 4),
  // Q3: `${genUID().slice(0, 6)}@${genUID().slice(0, 3)}.co.il`,
  Q4: "M",
  Q5: "22",
  Q6: "978",
  Q8: "29",
  Q9: "A5Q9",
  Q11: "A4Q11",
  Q18: "2",
  Q10: "500000",
  Q19: "A3Q19",
  Q14: "A5Q14",
  Q13: "A4Q13",
  Q16: "A5Q16",
  Q12: "A3Q12",
  Q20: "A4Q20",
  Q21: "22",
})

export function paramsArrayToObject(route: RouteLocation): { type: string; group: string } {
  const params = route.params as Record<string, string>

  return {
    type: params?.type?.toUpperCase(),
    group: params?.group?.toUpperCase(),
  }
}

export const nameByLang = (name: string) => {
  const { $i18n } = useNuxtApp()

  return $i18n.locale.value === "he" ? name : `${name}En`
}

export const nameByLangAndGender = (name: string) => {
  const { locale } = useI18n()
  const user = useUser()

  if (!(locale.value === "he")) {
    return `${name}En`
  }
  if (user?.user.value?.gender) {
    const isFemale = user.user.value.gender === "F"

    return `${name}He` + (isFemale ? "Female" : "Male")
  } else {
    return `${name}HeMale`
  }
}
